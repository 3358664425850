import { useEffect } from 'react';

const useMirrorTouchEvents = () => {
  const touchHandler = (event) => {
    const touches = event.changedTouches,
      first = touches[0],
      type = '';

    switch (event.type) {
      case 'touchstart':
        type = 'mousedown';
        break;
      case 'touchmove':
        type = 'mousemove';
        break;
      case 'touchend':
        type = 'mouseup';
        break;
      default:
        type = event.type;
        break;
    }

    // initMouseEvent(type, canBubble, cancelable, view, clickCount,
    //                screenX, screenY, clientX, clientY, ctrlKey,
    //                altKey, shiftKey, metaKey, button, relatedTarget);

    const simulatedEvent = document.createEvent('MouseEvent');
    simulatedEvent.initMouseEvent(
      type,
      true,
      true,
      window,
      1,
      first.screenX,
      first.screenY,
      first.clientX,
      first.clientY,
      false,
      false,
      false,
      false,
      0 /*left*/,
      null,
    );

    first.target.dispatchEvent(simulatedEvent);
    try {
      event.preventDefault();
    } catch (err) {}
  };

  useEffect(() => {
    document.addEventListener('touchstart', touchHandler, true);
    document.addEventListener('touchmove', touchHandler, true);
    // document.addEventListener('touchend', touchHandler, true);
    document.addEventListener('touchcancel', touchHandler, true);

    return () => {
      document.removeEventListener('touchstart', touchHandler, true);
      document.removeEventListener('touchmove', touchHandler, true);
      document.removeEventListener('touchend', touchHandler, true);
      document.removeEventListener('touchcancel', touchHandler, true);
    };
  }, []);
};

export default useMirrorTouchEvents;

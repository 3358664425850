import { useState, useCallback, useEffect } from 'react';
import FormInput from '../../components/common/contactForm/FormInput';
import { emailRegex } from '../../utils/regex';
import { getUserByToken } from '../../database/users';
import kebabCase from 'lodash/kebabCase';
import { BsFillPersonFill, BsLockFill } from 'react-icons/bs';
import { ImSpinner6 } from 'react-icons/im';
import { AiFillCheckCircle } from 'react-icons/ai';
import useAuth from '../../hooks/useAuth';
import useWindowSize from '../../hooks/useWindowSize';
import Image from 'next/image';
import useMirrorTouchEvents from '../../hooks/useMirrorTouchEvents';
import ReactSliderVerify from 'react-slider-verify';
import 'react-slider-verify/dist/index.css';

export async function getServerSideProps({ req, res }) {
  // check token to see if user exists
  const { user } = await getUserByToken(req, res);

  //if there is a user take them to their company page
  if (user) {
    if (user.roles.some(({ role: { role } }) => role === 'USER')) {
      return {
        redirect: { destination: `/enterprise/${kebabCase(user.domain.name.toLowerCase())}`, permanent: false },
      };
    }
    return { redirect: { destination: '/', permanent: false } };
  }

  //if there is not a user just keep them here
  return { props: { user: null } };
}

export default function Login() {
  const { signIn } = useAuth();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [notABot, setNotABot] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);
  const { width: windowWidth } = useWindowSize();
  useMirrorTouchEvents();

  const sliderSuccess = () => setNotABot(true);

  const validateForm = useCallback(() => {
    const errors = {};
    if (!formData.email || !emailRegex.test(formData.email)) {
      errors.email = 'Please provide a valid email';
    }

    if (!formData.password) {
      errors.email = 'Please provide a password';
    }

    setFormErrors({ ...formErrors, ...errors });

    return Object.keys(errors).length === 0;
  }, [formData]);

  const handleFieldChange = ({ target: { name, value } }) => {
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    setFormErrors({ ...formErrors, [name]: null });
  };

  const failureCallback = (errors) => {
    setFormErrors(errors);
    setLoading(false);
  };

  const successCallback = () => {
    setFormErrors({});
    setRedirecting(true);
    setLoading(false);
  };

  const handleLogin = (e) => {
    if (!loading) {
      if (e?.preventDefault) {
        e.preventDefault();
      }
      const validData = validateForm();
      if (validData) {
        setLoading(true);
        signIn(formData.email, formData.password, successCallback, failureCallback, true);
      }
    }
  };

  useEffect(() => {
    setInputWidth(document.getElementById('password').getBoundingClientRect().width);
  }, [windowWidth]);

  return (
    <section id="login-section" className="relative py-20 lg:px-32" style={{ backgroundColor: '#F2F2F2' }}>
      <div className="flex sm:shadow-lg sm:w-3/4 mx-auto sm:px-0 px-5 flex-wrap">
        <div
          className="md:block hidden w-full md:w-1/2 rounded-l-md min-h-full flex items-center overflow-hidden"
          style={{ maxHeight: '600px' }}
        >
          <Image
            src="/images/misc/login-image.jpg"
            alt="Terrestrial Imaging"
            layout="responsive"
            height={300}
            width={200}
            placeholder="blur"
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsX+ZXDwAE8wHs/+stmAAAAABJRU5ErkJggg=="
            loading="eager"
            priority
          />
        </div>
        <form
          className="w-full md:w-1/2 w-full justify-center items-center py-20 px-10 xl:px-28 mx-auto bg-white rounded-r-md sm:shadow-none shadow-lg"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <p className="text-3xl mb-10 font-semibold sm:px-3">Login</p>
          <FormInput
            key="email"
            containerClasses="w-full sm:px-3 mb-6 relative"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleFieldChange}
            placeholder="Email Address"
            error={formErrors.email}
            required
            Icon={<BsFillPersonFill />}
            label="Email"
            disabled={loading}
          />
          <FormInput
            key="password"
            containerClasses="w-full sm:px-3 mb-6 relative"
            name="password"
            id="password"
            value={formData.password}
            onChange={handleFieldChange}
            placeholder="Password"
            type="password"
            autoComplete="new-password"
            error={formErrors.password}
            required
            Icon={<BsLockFill />}
            label="Password"
            disabled={loading}
          />
          {inputWidth > 0 && (
            <div
              className="sm:px-3 w-full flex items-center mx-auto mb-5 text-xs text-ti-space-grey text-left justify-center"
              key={inputWidth}
            >
              <div className="flex items-center shadow">
                <ReactSliderVerify
                  onSuccess={sliderSuccess}
                  tips="Please swipe right to verify"
                  successTips="Verified"
                  barWidth={40}
                  width={inputWidth}
                  successBar={
                    <div className="flex items-center h-full">
                      <AiFillCheckCircle className="text-success text-xl m-auto" />
                    </div>
                  }
                />
              </div>
            </div>
          )}
          <div className="flex items-center justify-center text-center">
            <button
              onClick={handleLogin}
              className="flex items-center justify-center text-center ti-button bg-ti-space-grey text-ti-green can-hover:hover:bg-ti-space-grey can-hover:hover:opacity-60 rounded-full w-40 mx-auto"
              disabled={loading || redirecting || !notABot}
              id="signin-button"
            >
              <p>{loading ? 'Logging In' : redirecting ? 'Redirecting' : 'Sign In'}</p>
              {(loading || redirecting) && <ImSpinner6 className="text-ti-space-grey text-sm animate-spin ml-2" />}
            </button>
          </div>
          <p className="text-sm text-center mt-5 opacity-60">
            For trouble logging in please contact us at 800-359-0530
          </p>
          <p className="text-sm text-center mt-1 mb-5 opacity-60">or let us know via the contact form</p>
        </form>
      </div>
    </section>
  );
}
